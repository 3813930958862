// 基本 * 重要信息配置

export default {
  api: "/api",
  productId: "如有",
  cdn: "静态资源",
  currency: "默认币种",
  lang: "默认语言",
  themeCode: "REDJPCARTOON", // CMS模版编号
  cryptoKey: "J1PTY42LFGZAYBSS"
}
