import { post } from "./config/config"

const getPath = (type: number, isLogin: boolean) => {
  let path = ""
  if ([1, 2, 4].indexOf(type) > -1) {
    path = "/open"
  } else {
    path = isLogin ? "" : "/open"
  }
  return path
}

/* 获取图形验证码 */
export const getSliderCaptcha = (params: any, isLogin: false, hideLoading: false) => {
  const _path = getPath(params.validateCodeType - 0, isLogin)
  return post(`/api/phoenix/user${_path}/validate/slider/gen`, params, { hideLoading: hideLoading })
}

/* 校验图形验证码 */
export const checkSliderCaptcha = (params: any, isLogin: false) => {
  const _path = getPath(params.validateCodeType - 0, isLogin)
  return post(`/api/phoenix/user${_path}/validate/slider/verify`, params)
}

/* 发送验证码 */
export const getVerifyCode = (params: any, isLogin: false) => {
  const _path = getPath(params.validateCodeType - 0, isLogin)
  return post(`/api/phoenix/user${_path}/validate/send`, params)
}


/* 验证6位码 */
export const verifyCode = (params: any) => post("/api/phoenix/user/open/validate/verify", params)

/* **************************--注册-start-********************************* */
/* 手机注册 */
export const regApiPhone = (params: any) => post("/api/phoenix/user/open/register/phone", params, { hideLoading: false })
/* 邮箱注册 */
export const regApiEmail = (params: any) => post("/api/phoenix/user/open/register/email", params, { hideLoading: false })

/* 极速注册 */
export const regApiFast = (params: any) => post("/api/phoenix/user/open/register/fast", params)
/* **************************--注册-end-********************************* */

/* **************************--登录-start-********************************* */
export const logApi = (params: any) => post("/auth/oauth/token", params)

export const logoutApi = () => post("/auth/oauth/logout")
/* 三方登录 */
export const thirdLogApi = (params: any) => post("/api/phoenix/user/open/auth/thirdParty/login", params)
/* **************************--登录-end-********************************* */

/* **************************--用户信息-start-********************************* */
export const getUserInfo = (params: any) => post("/api/v1/player/query", params, { hideLoading: true })
export const getKycUserInfo = (params: any) => post("/api/v1/player/kyc/query_player_info", params, { hideLoading: true })
export const updateKycUserInfo = (params: any) => post("/api/v1/player/kyc/update_player_info", params)

/* 用户注册-更改用户名-和密码 */
export const newRegEditUser = (params: any) => post("/api/phoenix/user/new/update", params)
/* 关闭修改弹窗-或者修改完成回调 */
export const newRegEditCallback = () => post("/api/phoenix/user/new/callback")
/* **************************--用户信息-end-********************************* */

// 找回 - 重置密码
export const forgetResetPwd = (params: any) => post("/api/phoenix/user/open/info/returnPwd", params)

// google一键登录 - 已取得credential的三方登陆
export const loginCredentialApi = (params: any) => post("/api/phoenix/user/open/auth/thirdParty/login/credential", params)

//  是否显示google一键登录
export const showFastLoginApi = (params: any) => post("/api/phoenix/user/open/auth/thirdParty/showFastLogin", params)

//  二级kyc 上传相片
export const uploadKycPicture = (params: any) => post("/api/v1/player/kyc/upload_kyc_picture", params)

//  二级kyc 提交
export const submitKycL2 = (params: any) => post("/api/v1/player/info/submit_kyc_l2", params)

//  添加玩家L2-L4等级KYC图片信息接口(前端调用)
export const kycAddPlayerInfoPicture = (params: any) => post("/api/v1/player/kyc/add_player_info_picture", params)

//  存储seon session
export const seonSession  = (params: any) => post("risk-api/v1/audit/seon/session", params)
