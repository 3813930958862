import { Md5 } from 'ts-md5'
import axios from 'axios'
import JSEncrypt from 'jsencrypt'
import { Base64 } from 'js-base64'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { message as AntdMessage } from '@/components/base/antdNotice'
import { parse, stringify } from 'lossless-json'

class Interceptor {
  constructor(http) {
    this.isPending = false // token刷新接口是否再请求中，true表示正在更新获取token
    this.isPendingTimer = '' // token刷新接口时，其它接口等待时的计时器

    this.config = this.configFun() // interceptor数据配置函数
    this.request(http) // request 函数
    this.response(http) // response 函数
  }
  // interceptor数据配置函数
  configFun() {
    return {
      // 配置headers字段
      getHeaders: (request) => {
        // 这个地方需要函数执行，直接配置会存在缓存
        const { commonConfig, isBigScreen, language } = useAppStore.getState()
        const { webToken, fingerPrintID } = useUserStore.getState()
        // const webToken = useUser().webToken || {}
        const headersConfig = {
          aid: isBigScreen ? commonConfig.appidForPc : commonConfig.appid,
          qid: Md5.hashStr(Date.now() + Math.random().toString().split('.')[1].slice(0, 6)),
          dm: location.hostname.replace('www.', ''),
          v: 'v1.0.0',
          mcode: commonConfig.merchantCode,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          lg: language,
          fnp: fingerPrintID, // 注意不同 id没接完，先hard code
          deviceId: fingerPrintID,
          ts: window.Date.now(),
          tz: new Date().toString().match(/([-+][0-9]+)\s/)[1],
          authorization: function () {
            // 有token且不是刷新token的接口调用时
            return webToken.access_token && request.data.grantType !== 'refresh_token'
              ? 'bearer ' + webToken.access_token
              : 'Basic ' + Base64.encode(commonConfig.authorization)
          },
          sign: function () {
            let requestData
            if (request.upload) {
              requestData = this.qid + this.aid + this.authorization()
            } else {
              // 在越南文签名时，params内的越南文和签名时使用params的签名不一致，从而导致签名失败的问题
              // 使用param处理时统一带上encodeURIComponent转码，看能不能解决该问题
              Object.keys(request.data).forEach((el) => {
                if (request.data[el] && typeof request.data[el] === 'string')
                  request.data[el] = request.data[el].normalize() // 变音字符转为等价的简单字符
              })
              requestData =
                stringify(request.data).split('').sort().join('').trim() +
                this.qid +
                this.aid +
                this.authorization()
            }
            return Md5.hashStr(requestData)
          }
        }

        for (let i in headersConfig) {
          if (headersConfig[i])
            request.headers[i] =
              typeof headersConfig[i] === 'function'
                ? headersConfig[i].bind(headersConfig)()
                : headersConfig[i]
        }
      },
      // 需要加密传输的key字段
      encryptKeys: [
        'password',
        'newPassword',
        'oldPassword',
        'mobile',
        'mobileNo',
        'phone',
        'accountNo',
        'withdrawPassword',
        'withdrawalPassword',
        'email',
        'pwd'
      ],
      // 需要重置已输入的验证码接口，清空输入+光标置于第一个输入框（谷歌2FA+邮箱）
      needResetCodeInputUrls: [
        '/oauth/token',
        '/player/create_account',
        '/email/bind',
        '/email/validate_email',
        '/google_authen/bind',
        '/google_authen/validate',
        '/phone/bind'
      ]
    }
  }
  // 接口请求前执行的函数
  request(http) {
    http.interceptors.request.use(
      async (request) => {
        // console.log('Request!', request)
        const { commonConfig, setGolabLoading } = useAppStore.getState()
        const { userInfo, isLogin } = useUserStore.getState()
        request.data = request.data || {}
        if (request.data.selfErrorHandle) {
          delete request.data.selfErrorHandle
          request.selfErrorHandle = true
        }
        if (request.data && !request.hideLoading) {
          setGolabLoading(true)
        }
        // api没有写excludeLoginName或参数无loginName或参数无excludeLoginName，则自动插入loginName
        if (!request.excludeLoginName && !request.data.loginName && !request.data.excludeLoginName) {
          if (userInfo?.loginName && isLogin) {
            request.data.loginName = userInfo.loginName
          }
        }
        // 参数带入excludeLoginName，删除excludeLoginName
        if (request.data && request.data.excludeLoginName) delete request.data.excludeLoginName

        // 加密数据
        this.config.encryptKeys.forEach((prop) => {
          if (request.data[prop]) {
            const encryptor = new JSEncrypt()
            encryptor.setPublicKey(commonConfig.webkey)
            const encryptData = encryptor.encrypt(request.data[prop])
            // 加密成功， 才賦值
            if (encryptData) {
              request.data[prop] = encryptData
            }
          }
        })

        if (request.data.upload) {
          delete request.data.upload
          let formData = new FormData()
          formData.append('merchantCode', commonConfig.merchantCode)
          if (request.data) {
            Object.keys(request.data).forEach((key) => {
              formData.append(key, request.data[key])
            })
          }
          request.upload = true
          request.data = formData
          this.config.getHeaders(request)
          request.headers['Content-Type'] = 'multipart/form-data'
        } else if (request.url.includes('_gameapi_/changeDisplayCurrency')) {
          // 针对自研游戏的接口做特殊处理
          request.headers = {
            Authorization: request.data.params,
            'Content-Type': 'application/json'
          }
          request.url += '?productId=' + request.data.productId
        } else if (request.data.customHeaders) {
          this.config.getHeaders(request)
          for (let i in request.data.customHeaders) {
            const headerVal = request.data.customHeaders[i]
            if (headerVal) request.headers[i] = headerVal
          }
          delete request.data.customHeaders
        } else {
          this.config.getHeaders(request)
        }
        if (request.data.isblob) {
          request.responseType = 'blob'
        }
        return request
      },
      (error) => {
        if (error.config && error.config.data) {
          const requestData = parse(error.config.data)
          !requestData.hideLoading && setGolabLoading(false)
        }
        return Promise.reject(error)
      }
    )
  }
  // 接口返回后执行的函数
  response(http) {
    http.interceptors.response.use(
      async (response) => {
        const { setGolabLoading, SET_GLOBAL_MSG } = useAppStore.getState()
        const { clearUserInfo } = useUserStore.getState()
        const { openLogin } = useAuthStore.getState()
        if (response.config.data) {
          if (typeof response.config.data === 'string') {
            const requestData = parse(response.config.data)
            !requestData.hideLoading && setGolabLoading(false)
          } else {
            setGolabLoading(false)
          }
        }
        const requestUrl = response.config.url
        const data = response.data
        let params = {}
        if (response.config.upload) {
          params.upload = true
          for (var [a, b] of response.config.data.entries()) {
            params[a] = b
          }
          delete params.merchantCode
        } else {
          params = parse(response.config.data)
        }

        // 1018 未登录调用需要登录的接口
        // 接口异常的单独处理
        if (response.data && !response.data.success) {
          if (params.refresh_token || +response.data.code === 1008) {
            clearUserInfo()
            console.log(1008, response.data)
            // router.push('/')
            // window.location.href = '/' // History API
            openLogin()
          } else if (this.config.needResetCodeInputUrls.some((url) => requestUrl.includes(url))) {
            // 重置验证码，清空输入+光标置于第一个输入框（谷歌2FA+邮箱）
            //store.commit("global/SET_RESETCODEINPUT", !store.state.global.resetCodeInput)
          } else if (+response.data.code === 1012) {
            // 后台禁用用户导致token
            //store.dispatch("user/resetData")
            //location.href = "/"
          }
          // 滑块验证码不需要错误提示
          if (+response.data.code === 100701) {
            return response.data
          }
          if (!response.config.selfErrorHandle) {
            if (
              response.config.url !== '/api/v1/common/getChannelStatisticsId' &&
              response.config.url !== '/api/v1/game/jackpot/agin/history'
            ) {
              data.msg && SET_GLOBAL_MSG({ flag: true, type: 'error', content: data.msg || 'Error' })
              // data.msg && AntdMessage.error(data.msg || 'Error')
              console.log('response.data request js ??? ', data.msg)
              // data.msg && _this.$error(data.msg || "Error")
            }
          }
        }
        // console.log('response end', response)
        return response.data
      },
      (error) => {
        console.log(error, 99999)
        const { setGolabLoading } = useAppStore.getState()
        if (error.config && error.config.data) {
          const requestData = parse(error.config.data)
          !requestData.hideLoading && setGolabLoading(false)
        }

        let errMsg = ''
        if (error.message.includes('timeout')) {
          errMsg = 'Request timed out'
        } else {
          const { response, message } = error
          errMsg = (response && response.data && response.data.message) || message
        }
        // 針對網路異常進行處理---网络异常不提示
        errMsg === 'Network Error' && (errMsg = '')
        errMsg && AntdMessage.error(errMsg)

        console.log('提示错误 request js ??? ', errMsg)
        // errMsg && _this.$error(errMsg)
        return Promise.reject(error)
      }
    )
  }
}

const http = axios.create({
  baseURL: '/_api_',
  withCredentials: true,
  timeout: 30000
})
new Interceptor(http)

export default http
