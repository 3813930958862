import { post, get } from "./config/config"
// 更改自我调控
export const selfControl = (params: any) => post("/api/v1/player/self_control", params)

// 帐户验证 - 上传身份验证文件
export const uploadIdApi = (params: any) => {
  return post("/api/phoenix/user/info/uploadId", params)
}

// 帐户验证 - 身份验证文件上传记录
export const uploadRecordApi = () => post("/api/phoenix/user/info/uploadRecord")

// 账户信息 - 修改
export const personalUpdateApi = (params: any) => {
  const str = params.birthDate
  if (str && str.indexOf("-") === -1 && str.length === 8) {
    params.birthDate = str.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
  }
  return post("/api/phoenix/user/info/personal/update", params)
}

// 账户信息 - 帐号设定/设置密码（该接口根据不同类型修改不同内容）
export const formatUpdateApi = (params: any) => post("/api/ext/v1/player/modify", params)

// 修改密码接口
export const resetPasswordApi = (params: any) => post("/api/v1/player/reset_password", params)

//更新用户指纹ID
export const updateUserFingerPrint = (params: any) => post("/api/phoenix/user/login/upd/fingerPrint", params)

// 账户信息 - 账号设定 - 获取时区数据
export const timeZoneApi = (params: any) => get("/api/phoenix/user/open/common/timeZone", params)

export const getpPromotionList = (params: any) =>
  post("/api/phoenix/user/promotion/list", params, { hideLoading: true })

//获取任务信息
export const getTaskList = (params: any) => post("/api/phoenix/prom/task/checkDisplay", params, { hideLoading: true })


//查询指定任务配置
export const designationTasks = (params: any) =>
  post("/api/phoenix/prom/open/task/designationTasks", params, { hideLoading: true })

//用户申请优惠
export const getTaskApply = (params: any) => post("/api/phoenix/prom/task/apply", params, { hiddeErr: true })

export const getTaskCancel = (params: any) => post("/api/phoenix/user/bonus/cancel", params)
export const getTaskCancelPre = (params: any) => post("/api/phoenix/user/promotion/cancel", params)
export const getTaskCancelDecline = (params: any) => post("/api/phoenix/user/promotion/decline", params)

export const checkDisplayBonus = (params: any) =>
  post("/api/phoenix/user/bonus/checkDisplayBonus", params, { hideLoading: true }) // 活动是否有数据

export const getpPromotionHistory = (params: any) =>
  post("/api/phoenix/user/promotion/history", params, { hideLoading: true })

export const getBonusHistory = (params: any) => post("/api/phoenix/user/bonus/history", params, { hideLoading: true })

// 用户接受优惠
export const claimPromotion = (params: any) => post("/api/phoenix/user/promotion/claim", params)
export const claimDecline = (params: any) => post("/api/phoenix/user/promotion/decline", params)
export const claimRevert = (params: any) => post("/api/phoenix/user/promotion/revert", params)

/* 根据登录名查询玩家存款列表接口(前端调用) */
export const queryFrontBalanceRecordByPage = (params: any) => post("api/v1/deposit/query_by_login_name", params)

/* 根据登录名查询玩家取款列表接口(前端调用) */
export const getPersonalWithdrawRecord = (params: any) => post("api/v1/withdraw/query_by_login_name", params)

/* 根据登录名查询玩家真钱额度记录信息接口(前端调用) */
export const getPersonalCreditRecord = (params: any) => post("api/v1/wallet/credit/query_list_by_login_name", params)

/* 交易记录页-我的投注(前端调用) */
export const getOwnBetRecord = (params: any) => post("api/v1/game/order/get_own_bet_record", params)

/* 优惠券列表（已领取）*/
export const bonusCouponPage = (params: any) => post("api/ext/v1/bonus/coupon/page", params)

/* 首页钱包-优惠券查询接口*/
export const bonusCouponSummary = (params: any) => post("api/ext/v1/bonus/coupon/summary", params)

/* 个人中心，已领取优惠金额接口*/
export const BonusCurrencyAmout = (params: any) => post("/api/v2/bonus/query_bonus_currency_amount", params)

/* VIP bonus领取接口 */
export const vipBonusClaim = (params: any) => post("/api/phoenix/user/promotion/claim/v2", params)
// export const queryFrontBalanceRecordByPage = (params: any) =>
//   post("/api/phoenix/user/balance/record/queryFrontBalanceRecordByPage", params)

//用户转移
export const userTransfer = () => post("/api/phoenix/user/transfer")

// 用户bonus历史纪录v2
export const getUserBonusHistoryNew = (params: any) => {
  return post("/api/phoenix/user/bonus/v2/history", params)
}

//用户bonus免费旋转记录
export const getFreeSpinPrizeDetail = (params: any) => {
  return post("/api/phoenix/user/bonus/freeSpinPrizeDetail", params, { hideLoading: true })
}

//用户取消bonus
export const cancelBonus = (params: any) => {
  return post("/api/phoenix/user/bonus/cancel", params)
}

//查询优惠券
export const inquireVoucher = (params: any) => {
  return post("/throne-api/cdk/type/prize", params)
}

//兑换优惠券
export const redeemVoucher = (params: any) => {
  return post("/throne-api/cdk/exchange", params)
}

/**
 * @description 登录后根据用户名-发送验证码 - 邮箱
 */
export const sendCodeByName = (params: any) => {
  return post(`/api/v1/email/send_code_by_name`, params)
}

/**
 * 发送验证码绑定-手机号（不需要验证图形码等）用途[1:注册;2:登录;3:绑定 4:取款;5:忘记密码 9:其他],示例值(1)
 */
export const phoneSendCodeByName = (params: any) => {
  return post(`/api/v1/phone/send_code_by_name`, params)
}

/**
 * 发送验证码绑定-手机号（不需要验证图形码等）用途[1:注册;2:登录;3:绑定 4:取款;5:忘记密码 9:其他]
 */
export const sendCodeByPhone = (params: any) => {
  return post(`/api/v1/phone/send_code_by_phone`, params)
}
// 发送验证码接口 忘记密码单独的接口
export const forgetPasswordSendCodeByPhone = (params: any) => {
  return post(`/api/v1/phone/check_send_phone`, params)
}
// 发送email验证码接口 忘记密码单独的接口
export const forgetPasswordSendCodeByEmail = (params: any) => {
  return post(`/api/v1/email/check_send_email`, params)
}
// 验证 验证码接口
export const validCodeByPhone = (params: any) => {
  return post(`/api/v1/phone/validate`, params)
}
// 验证email验证码接口
export const validCodeByEmail = (params: any) => {
  return post(`/api/v1/email/validate_email`, params)
}

export const bindPhone = (params: any) => {
  return post(`/api/v1/phone/bind`, params)
}

/**
 * @description 注册新用户
 */
export const playerCreateAccount = (params: any) => {
  return post(`/api/v1/player/create_account`, params)
}

/**
 * 发送验证码绑定-邮箱[1:注册;2:登录;3:绑定 4:取款;5:忘记密码;6:绑定银行卡;7:修改邮箱 9:其他]
 */
export const sendCodeByEmail = (params: any) => {
  return post(`/api/v1/email/send_code_by_email`, params)
}

export const bindEmail = (params: any) => {
  return post(`/api/v1/email/bind`, params)
}

//登录获取token
export const oauthTokenApi = (params: any) => {
  return post(`/auth/oauth/token`, params)
}
//修改头像
export const editAvatar = (params: any) => {
  return post(`/api/v1/player/modify_player_avatar`, params)
}

//查询玩家奖励统计
export const rewardStatistics = (params: any) => {
  return post(`/api/v1/reward/statistics`, params)
}
//查询奖励对应玩家等级列表
export const rewardLevel = (params: any) => {
  return post(`/throne-api/activity/queryLevelRewardList`, params, { hideLoading: true })
}

