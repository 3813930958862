// import { watch } from "vue"
// import { _this } from "@/plugins/globalProperties"
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { checkReportStatus, updateReportStatus } from '@/api/base'
import { depositAutoTransInGame, transferAsyncTransferIn } from '@/api/game'
// import { appsFlyerEvent } from '@/lib/utils'
declare global {
  interface Window {
    Intercom: any
    jsBridge: any
    Android: any
    fbq: any
    YT: any
    yunweiConfig: any
    BTRenderer: any
    BTDomInit: any
    apkClient: any
    dataLayer: any
    dlag_67775a69_38c7_41dd_81de_2d3e0690fbc0: any
    dlag_8f7bc8de_0c25_4464_ba8a_f5afa6cb4364: any
    timerRakeback: any
    seon: any
  }
}

/**
 * @params params: 时间件名
 * @param data
 */
export const track = (params: string, data?: any) => {
  const appStore = useAppStore.getState()
  // const { isBigScreen } = useAppStore.getState()
  try {
    if (window.fbq) {
      window.fbq('track', params, data) // FB统一埋点
    }

    if (!appStore.isBigScreen) {
      window.Android?.adjustEvent(params, JSON.stringify(data || {}) || '')
      // appsFlyerEvent(params) // AF统一埋点  // 暂时不用
      window.jsBridge?.postMessage(params, JSON.stringify(data || {}) || '') // 安卓APP马甲包返回
    }
  } catch (error) {
    console.log(error, '埋点报错')
  }
}

/**
 *  Meta Pixel 埋点hook
 * @returns
 */

export const trackFristDeposit = () => {
  const appStore = useAppStore.getState()
  const useStore = useUserStore.getState()
  const id = useStore.userInfo.id
  const trackPurchase = (params: any) => {
    // appsFlyerEvent('FirstDeposit')  // 暂时不用
    track('Purchase', {
      value: params.value,
      currency: params.currency.toUpperCase() === 'USDT' ? 'USD' : 'JPY'
    })
    window.dataLayer.push({
      UsernameID: useStore.userInfo.loginName,
      AmountID: params.value,
      CurrencyID: params.currency,
      event: 'FTD.success'
    })
  }
  if (id && !localStorage.getItem('trackFirstDeposit_' + id)) {
    checkReportStatus({
      id,
      merchantCode: appStore.commonConfig.merchantCode
    }).then((res: any) => {
      if (res && res.success && res.data.reportStatus === 1) {
        localStorage.setItem('trackFirstDeposit_' + id, 'true')
        const value = res.data.firstDepositAmount
        const currency = res.data.firstDepositCurrency
        updateReportStatus({
          playerId: id,
          merchantCode: appStore.commonConfig.merchantCode,
          reportStatus: 2
        })
          .then((res1: any) => {
            if (res1 && res1.success) {
              trackPurchase({ value, currency })
            } else {
              localStorage.removeItem('trackFirstDeposit_' + id)
            }
          })
          .catch(() => {
            localStorage.removeItem('trackFirstDeposit_' + id)
          })
      } else if (res && res.success && res.data.reportStatus === 2) {
        localStorage.setItem('trackFirstDeposit_' + id, 'true')
      }
    })
  }
}

export const deposiTransInGame = (currency: any) => {
  depositAutoTransInGame({}).then((res: any) => {
    if (res.data.supportAutoTransIn === 1) {
      transferAsyncTransferIn({
        currency,
        fiatCurrency: 'USD',
        providerCode: 'KAMIKAZE',
        providerCurrency: 'USD'
      })
    }
  })
}
