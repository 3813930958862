import CryptoJS from "crypto-js"
/* ----------------加密相关处理------------- */
/* 随机生成秘钥 */
export const randomGenKey = (n = 16) => {
  const _chars = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ]
  if (!n || n == null) {
    n = 16
  }
  let _result = ""
  for (let i = 0; i < n; i++) {
    const id = Math.ceil(Math.random() * 35)
    _result += _chars[id]
  }
  return _result
}

/**
 * @description DES 加密
 * @param value
 * @param key
 * @returns {string}
 */

export function Encrypt(params: any, randomKey: any) {
  if (randomKey) {
    const key = CryptoJS.enc.Utf8.parse(randomKey)
    const srcs = CryptoJS.enc.Utf8.parse(params)
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
  }
}

/**
 * @description DES 解密
 * @param value
 * @param key
 * @returns {string}
 */

export function Decrypt(params: any, key: any) {
  if (key) {
    const _key = CryptoJS.enc.Utf8.parse(key)
    const decrypt = CryptoJS.AES.decrypt(params, _key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return decrypt.toString(CryptoJS.enc.Utf8)
  }
}
