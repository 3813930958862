'use client'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
// import { useShallow } from 'zustand/react/shallow'
import { notFound } from 'next/navigation'
import { formatUpdateApi } from '@/api/account'
import { getScrollWidth } from '@/lib/utils'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'next/navigation'
import {
  cmsTemplateApi,
  cmsTemplateDetailApi,
  cmsTemplatePageApi,
  getCommonConfig,
  getPublicKey,
  commonCheckIp
} from '@/api/base'
import { IAppStroe } from '../types/IStore'

// 创建状态存储
export const useAppStore = create<IAppStroe>()(
  persist(
    immer((set, get) => ({
      tipsDialogFlag: false, // 进游戏提示
      mainTheme: 'red',
      language: '', // 用于样式 如es-US, ja-JP
      // isBigScreen: global?.window && window.innerWidth > 999, //// 是不是大尺寸，即大于999px，即判定为PC版本
      isBigScreen: false, // 默认小屏，入口会加读取当前屏宽度的方法
      currentThem: '',
      globalLoading: false, //全局loading-flag
      isShowSideNav: false, // 是否展示H5侧边栏菜单
      isShowNotification: false, // 是否展示小铃铛侧边栏菜单
      isShowAvatar: false, // 是否展示头像更改弹框
      isShowAddToHome: false,
      isShowNoticeSysDialog: false, // 系统通知
      countryCurInfo: {
        alpha2: 'JP',
        code: '81',
        codeToInt: 81,
        country: 'Japan',
        countryFullName: 'Japan',
        countryCode: null
      }, //国家信息
      // 全局消息弹窗
      globalMsg: {
        flag: false, // 消息开关
        type: 'success',
        content: '', // 消息内容
        count: 3 // 倒计时
      },
      /* 全局加密-publicKey */
      globalPublickKey: '',
      dialogType: {
        forget: false
      },
      unreadMsgCount: 0,
      serviceIsShow: true,
      serviceCloseHintShow: false,
      serviceCMSHaveData: false,
      isShowFastDeposit: false,
      isOpenGameAudio: true, // 是否开启游戏音效
      previewDialogData: {
        visible: false,
        gameData: {
          guessGameName: '',
          trailerUrl: ''
        }
      },
      introduceDialog: false, //网站介绍弹框
      isShowSameAlert: false, //【QC-PC/H5】任务中心互斥弹窗 REX-6714
      mutualTaskList: [],
      commonConfig: <any>{
        productId: 'jP8InKJj7vjrjKbQMQm5IRthP2HebJEX',
        appid: '57db4f6e07644286aa880e3d0377cc83',
        appidForPc: '406f817d68df42368af89d2540993fa0',
        authorization: 'kratos-client:123456',
        merchantCode: '1b0c73df8b',
        webkey:
          'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCnj02qwBE8YQWX/jpUZtyrB5QD7ieBLlYbf8EbNE6z9/EZcV7/J/AnaClrFHYhlnqb3Tc2LSNU20ls9TKxateNV5lFxlr4Opr7tDOBXme9L3x7YliG4Mxfn8zG+MHIGx6L/O/nEw876xH9SCAMlijC9Cz2sgkkOpQ6rsdUZAohOwIDAQAB'
      }, // 商户配置信息
      limitLogin: false,
      showLimitLogin: false,
      accessType: 0,
      isNewVer: true, // 首页是否为新版
      memberDialog: false, // 会员信息相关弹窗
      memberDetail: {
        type: 'detail',
        data: {}
      }, // 游戏会员详情
      rewardshowSwiper: true, //任务中心排列方式
      // actions
      SET_TASK_SAME_ALERT: (isShowAlert, mutualTaskList, taskId) => {
        set({ isShowSameAlert: isShowAlert })
        if (mutualTaskList) {
          if (taskId) {
            set({
              mutualTaskList: mutualTaskList.sort((a, b) => {
                if (a.taskId === taskId) {
                  return 1
                } else {
                  return 0
                }
              })
            })
          } else {
            set({ mutualTaskList: mutualTaskList })
          }
        }
      },
      queryCommonConfig: async () => {
        // const { currencyDisplay, getCurrencyRate } = useUserStore(
        //   useShallow((state) => ({
        //     currencyDisplay: state.currencyDisplay,
        //     getCurrencyRate: state.getCurrencyRate
        //   }))
        // )
        const { currencyDisplay, getCurrencyRate } = useUserStore.getState()
        const res = await getCommonConfig({})
        if (res.data) {
          set({ commonConfig: { ...get().commonConfig, ...res.data } })
          if (!get().commonConfig.appid || !get().commonConfig.appidForPc) {
            set({
              commonConfig: {
                appid: '57db4f6e07644286aa880e3d0377cc83',
                appidForPc: '406f817d68df42368af89d2540993fa0'
              }
            })
          }
          if (currencyDisplay !== '') {
            getCurrencyRate()
          }
        }
      },

      /* 请求public-key */
      requestPublicKey: async () => {
        const result: any = await getPublicKey()
        // 配置公共key信息
        if (result?.successful) {
          set({ globalPublickKey: result.data || '' })
          return result
        }
      },
      acccesslimit: async () => {
        const res: any = await commonCheckIp()
        if (
          res.success &&
          res.data.accessLimit &&
          res.data.region !== 'localhost' &&
          global?.window &&
          window.navigator.userAgent.indexOf('prerender') === -1
        ) {
          // accessType 1   限制登录注册  accessType 2   限制访问（403）
          set({ limitLogin: true, showLimitLogin: true })
          if (res.data.accessType === 2) {
            set({ accessType: 2 })
            // _this.$router.push('/403')
            notFound()
          }
        } else {
          set({ accessType: 0, limitLogin: false })
        }
      },
      // 读取不同的环境
      updateCmsTemplate: async (params) => {
        let res = null
        if (params?.isDetail) {
          res = await cmsTemplateDetailApi(params)
        } else {
          if (params?.isPage) {
            res = await cmsTemplatePageApi(params)
          } else {
            res = await cmsTemplateApi(params)
          }
        }
        // console.log('读取的是那个', JSON.parse(JSON.stringify(params)), res)
        const { code, success, data } = res || {}
        if (code === 200 && success) {
          return Promise.resolve(data)
        } else {
          return Promise.reject(res)
        }
      },
      changeFastDialog: (isShow) => set({ isShowFastDeposit: isShow }),
      setDialogType: (v) => {
        set({ dialogType: Object.assign(get().dialogType, v) })
      },
      changeLanguage: async (lang) => {
        console.log('改变', lang)
        const router = useRouter()
        // const { isLogin, userInfo } = useUserStore(
        //   useShallow((state) => ({
        //     isLogin: state.isLogin,
        //     userInfo: state.userInfo
        //   }))
        // )
        const { isLogin, userInfo } = useUserStore.getState()
        if (!lang || get().language === lang) return
        set({ language: lang })
        // --- 待 处理
        if (!isLogin) {
          // location.href = lang === 'ja-JP' ? '/ja/' : '/'
          return
        }
        formatUpdateApi({
          lang,
          modifyType: 10,
          id: userInfo?.id,
          lastUpdatedBy: userInfo?.loginName
        }).then(async (res: any) => {
          if (res.success) {
            // 待观察  跳转状态 应该是不用刷新的
            console.log('语言切换请求成功')
            // location.href = lang === 'ja-JP' ? '/ja/' : '/'
          }
        })
      },
      setIsShowNotification: (data) => {
        const targetNode = document.getElementsByTagName('body')[0]
        if (data) {
          global?.window && window.document.body.classList.add('ant-scrolling-effect')
          targetNode.style.setProperty('overflow', 'hidden')
          targetNode.style.setProperty('width', `calc(100% - ${getScrollWidth()}px)`)
        } else {
          global?.window && window.document.body.classList.remove('ant-scrolling-effect')
          targetNode.style.removeProperty('overflow')
          targetNode.style.removeProperty('width')
        }
        set({ isShowNotification: data })
      },
      updatePreviewDialog: (data) => set({ previewDialogData: data }),
      setIsBigScreen: (data) => set({ isBigScreen: data }),
      setGolabLoading: (data) => {
        // console.log('打开loading 数据', data)
        set({ globalLoading: data })
      },
      setIsShowSideNav: (data) => set({ isShowSideNav: data }),
      setTipsDialogFlag: (data) => set({ tipsDialogFlag: data }),
      setIsShowAvatar: (data) => set({ isShowAvatar: data }),
      setIsShowAddToHome: (data) => set({ isShowAddToHome: data }),
      setIsShowNoticeSysDialog: (data) => set({ isShowNoticeSysDialog: data }),
      /* 国家信息 */
      SET_COUNTRY_INFO: (data) => set({ countryCurInfo: data }),
      /* 自定义全局信息 */
      SET_GLOBAL_MSG: (data) => set({ globalMsg: data }),
      SET_LANGUAGE: (data) => set({ language: data }),
      SET_iSOPEN_GAME_AUDIO: (data) => set({ isOpenGameAudio: data }),
      updateUnreadMsgCount: (data) => set({ unreadMsgCount: data }),
      updataServiceIsShow: (data) => set({ serviceIsShow: data }),
      updateServiceCloseHintShow: (data) => set({ serviceCloseHintShow: data }),
      updateServiceCMSHaveData: (data) => set({ serviceCMSHaveData: data }),
      updateIntroduceDialog: (data) => set({ introduceDialog: data }),
      updateLimitLoginDialog: (data) => set({ showLimitLogin: data }),
      SET_MEMEBER_DIALOG: (data) => set({ memberDialog: data }),
      SET_MEMEBER_DETAIL: (data) => set({ memberDetail: data || {} }),
      SET_IS_NEW_VERSION: (data) => set({ isNewVer: data }),
      SET_REWARD_SHOW_SWIPER: (data) => set({ rewardshowSwiper: data }),
      SET_PUBLIC_KEY: (data) => set({ globalPublickKey: data })
    })),
    {
      name: 'appStore', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage) // (optional) by default, 'localStorage' is used
    }
  )
)
