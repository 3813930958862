export const virtualArr = ["USDT", "USDC", "BTC", "ETH", "DOGE", "LTC", "TRX", "DAI", "XRP", "ADA","SHIB","SOL"]

export default {
  // 底部支持关注平台
  FollowUs: [
    {
      name: "Discord",
      path: "https://lihi.cc/3jTD6"
    },
    {
      name: "Telegram",
      path: "https://t.me/queencasino777"
    },
    // {
    //   name: "Tiktok",
    //   path: "https://www.tiktok.com"
    // },
    {
      name: "Twitter",
      path: "https://twitter.com/queencasino_0"
    },
    {
      name: "Youtube",
      path: "https://www.youtube.com/channel/UCuc0gyUhzB40vVyItDbplrQ"
    },

    {
      name: "LINE",
      path: "https://uyu4ktjm.autosns.app/line"
    },
    {
      name: "Instagram",
      path: "https://www.instagram.com/queencasino.official/"
    }

    // {
    //   name: "Facebook",
    //   path: "https://www.facebook.com/QueenCasino0"
    // }
  ]
}
