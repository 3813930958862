// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import request from "./request"
import requestOld from './request-old'

export const get = (url: string, params?: any) => {
  if(verifyUrl(url)){
    return requestOld.get(url, { params })
  }else{
    return request.get(url, { params })
  }
}

export const post = (url: string, params?: any, options?: any) => {
  if(verifyUrl(url)){
    return requestOld.post(url, params, options)
  }else{
    return request.post(url, params, options)
  }
}

//验证字符串
function verifyUrl(url){
  if(url.includes('/phoenix/') || url.includes('/rex/')){
    return true
  }else{
    return false
  }
}