// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'
// import { useAppStore } from '@/stores/app'
import { format, parseISO } from 'date-fns'
import { popNumFormat } from './filter'

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

export function slugify(str) {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

export function formatDate(
  date,
  options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }
) {
  return new Intl.DateTimeFormat('en-US', {
    ...options
  }).format(new Date(date))
}

// export function formatTime(
//   date,
//   options = {
//     month: 'long',
//     day: 'numeric',
//     year: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     second: 'numeric'
//     // hour12: false,
//   }
// ) {
//   return new Intl.DateTimeFormat('en-US', {
//     ...options
//   }).format(new Date(date))
// }

// 判单是否是图片
export function isImageUrl(url) {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg']
  try {
    // 创建一个新的 URL 对象
    const parsedUrl = new URL(url)
    if (parsedUrl.pathname) {
      const extension = parsedUrl.pathname.split('.').pop()?.toLowerCase() // 取URL部分路径，并提取文件扩展名
      return extension ? imageExtensions.includes(extension) : false // 检查扩展名是否在定义的图片扩展名列表中
    }
  } catch (e) {
    return false // 如果 URL 无效 返回 false
  }
}

// 根据指定属性 对数组对象进行排序
export const sortObject = (property) => {
  return function (a, b) {
    const val1 = a[property]
    const val2 = b[property]
    return val1 - val2
  }
}

export const resetObjToPrimitiveType = (obj) => {
  for (const key in obj) {
    obj[key] = undefined
  }
}

/**
 * 树结构查找子节点
 * @param tree 树结构数据
 * @param curKey 值
 * @param keyField  匹配字段
 * @param node  节点对象
 */
export const findCurNode = (tree, curKey, keyField, node = null) => {
  tree.forEach((item) => {
    if (item[keyField] === curKey) {
      node = item
    }
    if (item.children && item.children.length) {
      const findChildren = findCurNode(item.children, curKey, keyField, node)
      if (findChildren) {
        node = findChildren
      }
    }
  })
  return node
}

export const formatFormRules = (form) => {
  const rules = {}
  form.forEach((item) => {
    rules[item.key] = item.rules
  })

  return rules
}

export const getUrlParameter = (url): any => {
  url = url == null ? window.location.href : url
  const search = decodeURIComponent(url)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

export const addClass = (obj, cls) => {
  const obj_class = obj.className
  const blank = obj_class !== '' ? ' ' : ''
  obj.className = obj_class + blank + cls
}

export const removeClass = (obj, cls) => {
  let obj_class = ' ' + obj.className + ' '
  obj_class = obj_class.replace(/(\s+)/gi, ' ')
  let removed = obj_class.replace(' ' + cls + ' ', ' ')
  removed = removed.replace(/(^\s+)|(\s+$)/g, '')
  obj.className = removed
}

// 前端生成UUID，不能保证绝对不重复
export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x&' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// export const appsFlyerEvent = (type, data) => {
//   const isBigScreen = useAppStore((state) => state.isBigScreen)
//   // const { isBigScreen } = useAppStore.getState()
//   if (isBigScreen) return
//   window.apkClient?.appsFlyerEvent(JSON.stringify({ event: type, data }))
// }

export const getScrollWidth = () => {
  const scroll = document.createElement('div')
  const scrollIn = document.createElement('div')
  scroll.appendChild(scrollIn)
  scroll.style.width = '100px'
  scroll.style.height = '50px'
  scroll.style.overflow = 'scroll'
  scroll.style.marginLeft = '-100000px'
  document.body.appendChild(scroll)
  const scrollInWidth = scrollIn.offsetWidth
  const scrollWidth = scroll.offsetWidth
  const tmp = setTimeout(() => {
    document.body.removeChild(scroll)
    clearTimeout(tmp)
  }, 10)
  return scrollWidth - scrollInWidth
}

export const isNullObject = (obj) => {
  return Object.keys(obj).length === 0
}

export const preloadImg = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => resolve(img)
    img.onerror = () => reject(img)
  })
}

// 监测是否已经成功获取到某个数据，获取成功后才返回往下执行
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const loadDataThen = (loadFun = async () => {}, time = 300, endTime = 60 * 1000) => {
  // 则每隔time询问一次是否处理成功获取到数据，获取到数据了再返回
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await loadFun()
    if (res) {
      resolve()
    } else {
      const timer = setInterval(async () => {
        endTime -= time
        const res = await loadFun()
        if (res) {
          clearInterval(timer)
          resolve()
        }
        if (endTime < 0) {
          clearInterval(timer)
          reject()
        }
      }, time)
    }
  })
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
/**
 * 传入图片文件返回生成的src
 */
export const dataURLtoFile = (base64, filename = 'image') => {
  if (!base64) {
    throw new Error('base64为必传参数')
    return
  }
  const arr = base64.split(',')
  const typeMatch = arr[0].match(/:(.*?);/)
  const mime = typeMatch[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

/**
 * 压缩图片的公共方法
 * path: 来源图片src，obj：转换的图片宽、高、品质，默认原宽高、品质0.7
 */
export const imageCompression = async (path, obj = {}) => {
  if (!path) {
    throw new Error('path为必传参数')
    return
  }
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = path
    // img.setAttribute('crossOrigin', 'Anonymous')
    img.onload = function () {
      // 默认按比例压缩
      let w = this.width,
        h = this.height
      const scale = w / h

      w = obj.width || (w > 1200 ? 1200 : w)
      h = obj.height || w / scale
      let quality = 0.8 // 默认图片质量为0.8
      //生成canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = w
      canvas.height = h
      ctx.drawImage(this, 0, 0, w, h)
      // 图像质量
      if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
        quality = obj.quality
      }
      // quality值越小，所绘制出的图像越模糊
      // resolve(canvas.toDataURL('image/jpeg', quality))
      resolve(canvas.toDataURL('image/png', quality))
    }
    img.onerror = function (err) {
      reject(err)
    }
  })
}

/**
 * 根据币种返回 币种符号
 * 如果是虚拟币，返回 空
 */
export const getCurrencySymbol = (currency) => {
  const symbolDisplays = {
    USD: '$',
    CNY: '¥',
    EUR: '€',
    JPY: '¥',
    KRW: '₩',
    IDR: 'Rp',
    INR: '₹',
    PHP: '₱',
    THB: '฿',
    VND: '₫',
    CAD: 'C$',
    MYR: 'RM',
    RUB: '₽',
    BRL: 'R$'
  }
  if (currency && symbolDisplays[currency]) {
    return symbolDisplays[currency]
  } else {
    return ''
  }
}
/**
 * 根据币种返回 币种符号
 * 如果是虚拟币，返回 币种名称
 */
export const getCurrencySymbol2 = (currency) => {
  const symbolDisplays = {
    USD: '$',
    CNY: '￥',
    EUR: '€',
    JPY: '￥',
    KRW: '₩',
    IDR: 'Rp',
    INR: '₹',
    PHP: '₱',
    THB: '฿',
    VND: '₫',
    CAD: 'C$',
    MYR: 'RM',
    RUB: '₽',
    BRL: 'R$'
  }
  if (currency && symbolDisplays[currency]) {
    return symbolDisplays[currency]
  } else {
    return currency
  }
}
/**
 * 整数 千分位 加逗号，小数部分不做处理
 * 如果是法币，保留2位小数，如果是虚拟币，保留8位小数
 */
export const decimalFormat = (num, cur = 'JPY') => {
  if (isNaN(num)) return num
  if (num == null || !num) num = 0
  if (typeof num === 'number') {
    num = num.toFixed(10) //避免因为num 是科学计数 后面string截取出现错误
  }
  num = String(num)
  let integer = '' //整数部分
  let decimal = '' //小数部分
  if (num.split('.')) {
    integer = num.split('.')[0]
    if (num.split('.').length > 1) {
      decimal = num.split('.')[1]
    }
  }

  integer = integer.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
  let numberlength = 2 //保留几位小数
  //如果是法币，保留两位，
  if (cur === 'JPY' || cur === 'USD') {
    numberlength = 2
  } else {
    numberlength = 8
  }
  if (decimal.length < numberlength) {
    decimal = decimal.padEnd(numberlength, '0')
  } else if (decimal.length > numberlength) {
    decimal = decimal.slice(0, numberlength)
  }
  return integer + '.' + decimal
}

export const encodeAccount = (cardNo) => {
  const startIndexOfEncode = Math.floor(cardNo.length / 4)
  const encodeNumber = Math.round(cardNo.length / 2)
  const encodeSymbol = '•'
  return (
    cardNo.substring(0, startIndexOfEncode) +
    encodeSymbol.repeat(encodeNumber) +
    cardNo.substring(startIndexOfEncode + encodeNumber, cardNo.length)
  )
}

export const formatTime = (dataTime: any, type = 'yyyy-MM-dd HH:mm:ss') => {
  if (!dataTime) return
  return format(parseISO(dataTime), type)
}
//数字格式化 添加千分位，不四舍五入，超过两位小数的截取两位小数
export const formatNumber = (num: any, len: any) => {
  if (!Number(num)) {
    return 0
  }
  num = num.toString()
  if (num.indexOf('.') > -1) {
    if (len === 0) {
      num = num.substring(0, num.indexOf('.'))
    } else {
      num = num.substring(0, num.indexOf('.') + len + 1)
    }
  }
  num = num.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
  return num
}

export const onHandleAlt = (item: any) => {
  return item?.mediaLibrary[0]?.remark || ''
}

// 增加全局方法引入
export const formatGameImg = (item: any, commonConfig) => {
  // const appStore = useAppStore.getState() // 改由外部传入
  if (item.bigMediaLibrary && item.bigMediaLibrary.fullWebpFilePath) {
    return item.bigMediaLibrary.fullWebpFilePath
  } else {
    if (!item.gameImageUrls) return ''
    const url = '/' + item.gameImageUrls
    return url ? commonConfig.cmsDomain + url : '' // 待观察 cmsDomain 不知从那里来
  }
}

/**
 *
 * @param {{data, isAllAmount, showCurrency}} options
 * @returns {string|number}
 */

export const formatBonusAmount = (options) => {
  const { data, isAllAmount = false, showCurrency = true } = options
  let str = ''
  const amount = isAllAmount
    ? Number(data.amount)
    : showCurrency
      ? Number(data.fetchAmount) || Number(data.bonusAmount)
      : Number(data)
  const currency = data.fetchCurrency || data.bonusCurrency
  str = `${popNumFormat(amount, currency)} ${showCurrency ? currency : ''}`
  return str
}

export const getBonusName = (item, language) => {
  // const language = useAppStore((state) => state.language)  // languang 外部传入
  if (item.configName) {
    const configName = JSON.parse(item.configName)
    const configItem =
      // configName.find((el) => el.lang === language) ||
      configName.find((el) => el.lang === language) || configName.find((el) => el.lang === 'en-US')
    return configItem?.name || ''
  } else {
    return item.bonusName || '------'
  }
}

export const formatGameProvider = (val) => {
  const data = JSON.parse(val)
  let str = ''
  const len = data.length
  data.forEach((item, index) => {
    if (index + 1 !== len) {
      str += `${item.provider}-${item.sub_provider} , `
    } else {
      str += `${item.provider}-${item.sub_provider}`
    }
  })
  return str
}

export const formatAmount = (amount: any, len = 2, isThousandths = true) => {
  if (amount <= 0 || !amount) return `0${len > 0 ? '.' : ''}` + ''.padEnd(len, '0')
  const str = String(Number(amount))
  const arr = str.split('.')
  const left = isThousandths ? arr[0].replace(/\B(?=(\d{3})+\b)/g, ',') : arr[0]
  let right = arr[1]
  if (right) {
    if (right.length >= len) {
      right = right.substring(0, len)
    } else {
      right = right.padEnd(len, '0')
    }
    return `${left}.${right}`
  }
  return len === 0 ? left : `${left}${len > 0 ? '.' : ''}` + ''.padEnd(len, '0')
}
