'use client'
import { App } from 'antd'

// 设置全局弹窗

let message // success, error, info, warning, loading
let notification // success, error, info, warning, open, destory
let modal // success, error, info, warning, confirm

const AppWrapper = () => {
  const staticFunction = App.useApp()
  message = staticFunction.message
  modal = staticFunction.modal
  notification = staticFunction.notification
  return null
}
export default AppWrapper
export { message, notification, modal }
