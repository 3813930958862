'use client'
import axios, { InternalAxiosRequestConfig } from 'axios'
import { parse } from 'lossless-json'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { message as AntdMessage } from '@/components/base/antdNotice'
// import { useRouter, usePathname } from 'next/navigation'
/* crypto----rsa */
import { randomGenKey, Encrypt } from '@/hooks/encrypt'
import JSEncrypt from 'jsencrypt'
import { Md5 } from 'ts-md5'
import { localeItems } from '@/lib/locales/settings'
// import { useTranslation } from '@/lib/i18n/client'
// import { getTranslations } from 'next-intl/server'
/* crypto----rsa */

interface Config extends InternalAxiosRequestConfig {
  hideLoading?: boolean
  hiddeErr?: boolean
  encryptFlag?: boolean //参数加密标识
}

let outTimeKey = false
// const getUserIp = requestUserIp()
// 存储上一个请求的配置
let lastRequestConfig = null

const customNumberParser = (value) => {
  return value.length < 16 ? parseFloat(value) : value
}

const service: any = axios.create({
  baseURL: '/',
  timeout: 30000,
  withCredentials: true,
  transformResponse: [
    (data) => {
      try {
        const result = parse(data, null, customNumberParser) // 待处理
        return result
      } catch (err) {
        console.log('transform data is wrong', err)
        return data
      }
    }
  ]
})

const langFilter = (lang) => {
  const arr = localeItems.filter((item) => item.name === lang)[0]?.name
  return !arr ? 'en-US' : arr
}

service.interceptors.request.use(
  async (config: Config) => {
    // console.log('request config', config)
    const { commonConfig, language, isBigScreen, globalPublickKey, setGolabLoading } = useAppStore.getState()
    const { webToken, fingerPrintID, userInfo } = useUserStore.getState()
    const _fileFlag = config?.url === '/api/phoenix/user/info/uploadId'
    /* type */
    if (config.method === 'post') {
      config.headers['Content-Type'] = 'application/json'
    }
    if (_fileFlag) {
      config.headers['Content-Type'] = 'multipart/form-data;'
    }
    /* headers */
    if (
      !config?.hideLoading &&
      config?.url !== '/api/phoenix/cms/game/favorite/gameFavorAdd' &&
      !config?.params?.hideLoading
    ) {
      setGolabLoading(true)
    }
    // console.log('data 请求', config.data)
    // 存储前一次请求的接口，用于重刷恢复
    lastRequestConfig = outTimeKey
      ? lastRequestConfig
      : {
          method: config.method,
          url: config.url,
          data: config.data,
          params: config.params,
          options: { hiddeErr: config.hiddeErr, hideLoading: config.hideLoading }
        }
    // ["Accept-Language"] 不允许请求头为CN，若出现，强制调整为EN;
    const lang = langFilter(language)
    // const lang = langFilter(AppStore.language)
    config.headers['qid'] = Md5.hashStr(Date.now() + Math.random().toString().split('.')[1].slice(0, 6))
    config.headers['Accept-Language'] = lang
    config.headers['lgts'] = lang
    config.headers['X-Website-Code'] = isBigScreen
      ? commonConfig.merchantCode + '_PC'
      : commonConfig.merchantCode + '_H5'
    config.headers['Authorization'] = webToken ? 'bearer ' + webToken.access_token : ''
    config.headers['MERCHANT-CODE'] = commonConfig.merchantCode
    config.headers['DEVICE-ID'] = fingerPrintID
    // config.headers['DEVICE-ID'] = '5cb9c4342b66882c9d75fc11c8624697' // 待处理 hard core
    const loginName = userInfo?.loginName
    if (loginName) {
      config.headers['LOGIN-NAME'] = loginName
    }

    if (config.data && loginName) {
      config.data.loginName = loginName
    }
    /* ---------全局参数加密处理-文件上传不加密-get不加密---------- */
    // console.log('=========参数组装', config.data)
    if (config.data && !_fileFlag && config?.url !== '/api/rex/agent/open/referralLinkLog/add') {
      const _randomKey = randomGenKey()
      /* AES-加密-params */
      const parameter = Encrypt(JSON.stringify(config.data), _randomKey)
      // const parameter = Encrypt(stringify(config.data), _randomKey)
      /* RSA-加密-RandomKey */
      const encryptor = new JSEncrypt()
      encryptor.setPublicKey(globalPublickKey)
      config.headers['X-Request-Encrypt'] = encryptor.encrypt(_randomKey) || _randomKey + 'NO-KEY'
      config.headers['X-Request-md5'] = Md5.hashStr((parameter + globalPublickKey).toUpperCase())
      config.data = parameter
    }
    return config
  },
  (error) => {
    console.log('request err', useAppStore)
    const { setGolabLoading } = useAppStore.getState()
    if (useAppStore) {
      setGolabLoading(false)
    }
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  async (response: any) => {
    // console.log('=========response old', response)
    // const { setGolabLoading } = useAppStore.getState()
    const res = response.data
    const errMsg: any = res.msg || res.message
    const config: any = response.config as Config
    // const [messageApi] = message.useMessage()
    /* hand-loading */
    if (useAppStore.getState() && !config.hideLoading) {
      useAppStore.getState().setGolabLoading(false)
      // const t = useTranslation('user')
      // AntdMessage.error(t && t('logOthes'))
    }
    if (res.code !== 200) {
      if (!config.hiddeErr) {
        /* 提示错误 */
        console.log('提示错误 ??? ', useAppStore)
        AntdMessage.error(errMsg)
      }
      return Promise.reject(res)
    } else {
      return Promise.resolve(res)
    }
  },
  async (error: any) => {
    const { setGolabLoading, requestPublicKey } = useAppStore.getState()
    const { clearUserInfo } = useUserStore.getState()
    const { openLogin } = useAuthStore.getState()
    // const router = useRouter()
    // const {t} = useTranslation()
    /* hand-loading */
    if (useAppStore.getState()) {
      setGolabLoading(false)
    }

    /* key失效 重新读取key值*/
    if (error.response?.data?.code === -120) {
      // 重刷接口
      console.log('-120', error.response)
      try {
        setGolabLoading(true)
        outTimeKey = true
        const res = await requestPublicKey()
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
        await delay(200)
        if (res?.successful) {
          // 重新组装上一次请求的接口并发出, 其中params待观察(get 请求)，还没测试拼接数据
          const method = lastRequestConfig.method
          const param = method === 'post' ? lastRequestConfig.data : lastRequestConfig.params
          return service[method](lastRequestConfig.url, param, lastRequestConfig.options) // 相当于xxx.post(url, data, options)
        }
      } catch (err) {
        console.log('err', err)
      } finally {
        setGolabLoading(false)
        outTimeKey = false
      }
      //  原项目代码，读取不到本地公共key 会进行一次刷次操作，会出现重刷现象，先注释观察一段时间
      // if (!outTimeKey) {
      // outTimeKey = true
      // const timer = setTimeout(() => {
      //   outTimeKey = false
      //   window.location.reload()
      //   clearTimeout(timer)
      // }, 500)
      // } else {
      //   // 3秒后才可二次出发key读取，防止接口同步重+复请求；
      //   console.log('复请求； ??? ', error)
      //   const timer2 = setTimeout(() => {
      //     outTimeKey = false
      //     clearTimeout(timer2)
      //   }, 3000)
      // }
    }

    if (error.response?.status === 401) {
      clearUserInfo()
      // 待观察
      // router.push('/')
      // window.location.href = '/' // History API 这个可能就是重刷的原因
      openLogin()
    }
    /* 多设备登录处理 */
    if (error.response?.data?.code === -130) {
      clearUserInfo()
      // 待观察
      // router.push('/')
      // window.location.href = '/' // History API
      // AntdMessage.error(t('logOthes'))
      // AntdMessage.error("Your account has been logged into from another device.")
    }
    return Promise.reject(error)
  }
)

export default service
